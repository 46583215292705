<template>
  <div class="row">
    <div class="col text-center">
      <h1 class="text-left">Cotizador</h1>
      <img width="50%" :src="require(`@/assets/images/icons/quoterIcons/quoter.png`)" class=""/>
    </div>
    <div class="col pt-4">
        <div v-for="(container, index) of containers" class="container-quoter-rows  mt-quoter-rows" :key="`container${index}`">
          <div class="container-quoter-number">{{ index + 1 }}</div>
          <form-render
            :ref="`formRenderQuoter${index}`"
            :key="keyForm[container.keyFormRender]"
            class="fieldQuoter"
            :fields="container.field"
            :form.sync="form"
          >
          </form-render>
        </div>
        <div v-if="form.country" class="container-quoter-rows  mt-quoter-rows" :key="'container5'">
          <div class="container-quoter-number number-top">5</div>
          <div class="w-100 row">
            <div class="d-flex col-12 col-lg-7 col-md-8 col-sm-12 pr-0">
              <div class="d-flex pl-1 pr-0 w-100" v-for="(estimatedValue, index) of estimatedValues" :key="`estimatedValue${index}`">
                <div class="container-img"><img :src="estimatedValue.img" class="weightComponent"/></div>
                <form-render
                  :ref="`estimatedValue${index}`"
                  :key="estimatedValue.keyFormRender"
                  class="fieldQuoter pl-1"
                  :fields="estimatedValue.field"
                  :form.sync="form"
                />
              </div>
            </div>
            <div v-if="form.country.id === 3" class="col-12 col-lg-5 col-md-4 col-sm-12">
              <form-render
                ref="formRenderDeclaredValue"
                :key="keyFormRenderDeclaredValue"
                class="fieldQuoter"
                :fields="fieldDeclaredValue"
                :form.sync="form"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <div class="container-quoter-rows mt-quoter-rows" :key="'container5'">
            <div class="container-quoter-number">5</div>
            <div class="w-100 row">
            <div class="d-flex col-12 col-md-10 pr-0">
              <div class="d-flex pl-1 pr-0 w-100" v-for="(estimatedValue, index) of estimatedValues" :key="`estimatedValue${index}`">
                <div class="container-img"><img :src="estimatedValue.img" class="weightComponent"/></div>
                <form-render
                  :ref="`estimatedValue${index}`"
                  :key="estimatedValue.keyFormRender"
                  class="fieldQuoter pl-1"
                  :fields="estimatedValue.field"
                  :form.sync="form"
                />
              </div>
            </div>
          </div>
          </div>
        </div>
        <b-button variant="warning" class="quoterButton" @click="validateForm" :disabled="loading.callingService">
          <span v-if="!loading.callingService" class="mb-1">Cotizar</span>
          <span class="mt-1 p-2" v-if="loading.callingService"><b-spinner small  /> </span>
        </b-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import weightIcon from '@/assets/images/icons/quoterIcons/Peso.png'
import BaseServices from '@/store/services/index'

export default {
  props: ['firstLoading', 'changeFirstLoading'],
  data () {
    return {
      baseService: new BaseServices(this),
      keyFormRenderDeclaredValue: 0,
      containers: [{field: []}],
      form: {},
      fieldObj: {
        stepOne: [],
        stepTwo: [],
        stepThree: [],
        stepFour:[]
      },
      loading: {
        callingService: false
      },
      keyForm: { 3: 0 },
      fieldDeclaredValue: [],
      estimatedValues: [],
      dataRow: []
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesPricingWeb'
    })
  },
  watch: {
    countries() {
      this.setCountries()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fieldObj.stepOne = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', disabled: false, label: 'País', validation: 'required', containerClass: 'col-12 col-md-10 col-lg-10 fieldSelect', change: this.changeCountry, addFlags: true }
      ]
      this.fieldObj.stepTwo = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'origin', label: 'Origen', validation: 'required', containerClass: 'col-12 col-md-10 col-lg-10', clearable: true, dependency: 'country', searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600 }}
      ]
      this.fieldObj.stepThree = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'destination', label: 'Destino', validation: 'required', disabled: false, containerClass: 'col-12 col-md-10 col-lg-10', dependency: 'country', clearable: true, searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600 }}
      ]
      this.fieldObj.stepFour = [
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'classification', label: 'Clasificación servicio', validation: 'required', containerClass: 'col-12 col-md-10 col-lg-10', dependency: 'country', clearable: true }
      ]
      this.fieldObj.weightValue = [
        { fieldType: 'FieldInput', name: 'weightValue', label: 'Peso promedio bulto.', type: 'number', validation: 'required', containerClass: 'col-12 col-sm-12 col-md-12 col-lg-12 ', append: {text: 'Kg', color: '#BBBBBB'}, dependency: 'country', clearable: true }
      ]
      this.fieldDeclaredValue = [
        { fieldType: 'FieldInput', name: 'declaredValue', label: 'Valor declarado', type: 'number', validation: 'required', containerClass: 'col-12 col-sm-12 col-md-12 col-lg-12 pl-2 input-declared-value', dependency: 'country', clearable: true }
      ]
      this.containers = [
        {field: this.fieldObj.stepOne, keyFormRender: 0},
        {field: this.fieldObj.stepTwo, keyFormRender: 1},
        {field: this.fieldObj.stepThree, keyFormRender: 2},
        {field: this.fieldObj.stepFour, keyFormRender: 3}
      ]
      this.estimatedValues = [
        {field: this.fieldObj.weightValue, keyFormRender: 0, img: weightIcon}
      ]
      this.$store.dispatch('fetchService', { name: 'getCountriesPricingWeb' })
    },
    setCountries() {
      this.fieldObj.stepOne[0].options = this.countries.rows
      this.setSkeletonField('stepOne', false)
    },
    getServicesTypes () {
      const params = {
        country_id: this.form.country.id
      }
      this.baseService.callService('getServiceTypesPricingWeb', null, params)
        .then(resp => {
          const serviceTypes = resp.data.filter(el => {
            if (this.form.country.code === 'MX' || this.form.country.code === 'CO') return el.type === 'nextday' || el.type === 'sameday' || el.type === 'estandar' || el.type === 'general'
            else return el.type === 'nextday' || el.type === 'sameday'
          })
          this.fieldObj.stepFour[0].options = serviceTypes.map(obj => ({ ...obj, text: obj.name }))
          this.setSkeletonField('stepFour', false)
          this.keyForm[3]++
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {
            code: err.message
          }))
        })
    },
    setSkeletonField (name, bool) {
      this.fieldObj[name][0].useSlot = bool
      this.fieldObj[name][0].useSkeleton = bool
      this.fieldObj[name][0].useLabel = bool
    },
    setPlaces(value) {
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {
        search: value
      }
      return this.baseService.callService('getPlacesPricingWeb', queryParams, params)
        .then(resp => {
          const response = resp.data.places.map(el => ({...el, text: el.full_name, valid: true}))
          return response
        })
        .catch(err => {
          return err.message
        })
    },
    changeCountry(name, value) {
      this.refreshElements(true)
      Object.keys(this.form).forEach(el => { if (el !== 'country') this.form[el] = undefined })
      if (value) {
        this.fieldObj.stepTwo[0].containerClass = 'col-12 col-md-10 col-lg-10 fieldSelect'
        this.fieldObj.stepThree[0].containerClass = 'col-12 col-md-10 col-lg-10 fieldSelect'
        this.fieldObj.stepFour[0].containerClass = 'col-12 col-md-10 col-lg-10 fieldSelect'
        this.getServicesTypes()
        this.refreshElements(false, 'stepFour')
      } else {
        Object.keys(this.form).forEach(el => { if (el !== 'country') this.form[el] = undefined })
        this.fieldObj.stepTwo[0].containerClass = 'col-12 col-md-10 col-lg-10'
        this.fieldObj.stepThree[0].containerClass = 'col-12 col-md-10 col-lg-10'
        this.fieldObj.stepFour[0].containerClass = 'col-12 col-md-10 col-lg-10'
        this.refreshElements(false)
      }
    },
    async validateForm () {
      if (!Number.isInteger(parseFloat(this.form.weightValue))) {
        await this.$refs.estimatedValue0[0].$refs.observer.setErrors({'Peso promedio bulto.': ['Por favor ingresa un numero entero']})
        this.setSkeletonField('weightValue', false)
      } else {
        this.form.weightValue = parseInt(this.form.weightValue)
        const value1 = await this.$refs.formRenderQuoter0[0].onlyCheckForm()
        const value2 = await this.$refs.formRenderQuoter1[0].onlyCheckForm()
        const value3 = await this.$refs.formRenderQuoter2[0].onlyCheckForm()
        const value4 = await this.$refs.formRenderQuoter3[0].onlyCheckForm()
        const value5 = await this.$refs.estimatedValue0[0].onlyCheckForm()
        const allValues = [value1 && value2 && value3 && value4 && value5]
        if (this.form.country.id === 3) allValues.push(await this.$refs.formRenderDeclaredValue?.onlyCheckForm())
        const isCheck = allValues.every(el => el === true)
        if (isCheck) this.sendForm()
      }
    },
    refreshElements(value, omit = null) {
      setTimeout(() => {
        Object.keys(this.fieldObj).map(key => {
          if (key !== omit || key !== 'stepOne') this.setSkeletonField(key, value)
        })
      }, 200)
    },
    sendForm() {
      this.loading.callingService = true
      const params = {
        country_id: this.form.country.id,
        from_place_id: this.form.origin.id,
        service_type_id: this.form.classification.id
      }
      const queryParams = {
        weight : this.form.weightValue,
        declared_value: this.form.declaredValue || '',
        ['to_place_ids[0]']: this.form.destination.id
      }
      this.baseService.callService('getComparatorPricesPricingWeb', queryParams, params)
        .then(resp => {
          this.dataRow = resp
          this.$emit('changeFirstLoading', true, this.form, [this.form.destination], resp, params, queryParams)
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {
            code: err.message
          }))
        })
        .finally(() => {
          this.loading.callingService = false
        })
    },
    setFieldsProperties (fields, name, propertyField, value) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index][propertyField] = value
      }
    }
  }
}
</script>

<style lang="scss">
.mt-quoter-rows{
 margin-top: 20px !important;
}
.badge__cnt{
  margin: 5px;
  background: #58bbe4;
  padding: 2px 5px;
}
.main-box-autocomplete {
  z-index: 99 !important;
  > .element-box-autocomplete{
    &:hover {
      white-space: normal !important;
    }
  }
}
.color_icon{ //color para el icono X de los tags
  stroke: white;
}
.input-declared-value .form-control{
  border-radius: 5px;
}
</style>