<template>
    <b-card class="pricing-web-height">
      <div id="pricing-web" class="h-100">
        <pricingWebForm 
          :firstLoading="firstLoading"
          @changeFirstLoading="changeFirstLoading"
          v-if="!firstLoading"
        >
        </pricingWebForm>
        <pricingWebFormList
          :formWeb="form"
          :valueTagsWeb="valueTags"
          :tableData="tableData"
          :params="params"
          :queryParams="queryParams"
          v-if="!!firstLoading"
        >
        </pricingWebFormList>
      </div>
    </b-card>
</template>
<script>
import pricingWebForm from './PricingWebForm.vue'
import pricingWebFormList from './PricingWebFormList.vue'

export default {
  components: { pricingWebForm, pricingWebFormList },

  data () {
    return {
      firstLoading: false,
      form: {},
      valueTags: [],
      tableData: {},
      params: {},
      queryParams: {}

    }
  },
  methods: {
    changeFirstLoading(bool, form, valueTags, tableData, params, queryParams) {
      this.form = form
      this.valueTags = valueTags
      this.firstLoading = bool
      this.tableData = tableData
      this.params = params
      this.queryParams = queryParams
    }
  }
}

</script>
<style lang="scss">
.pricing-web-height {
  min-height: 100vh;
}
</style>