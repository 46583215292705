<template>
  <div class="pricing-web-table">
    <h2 class="title-table-pricing-web" v-if="rows.length">Top 5 tarifas</h2>
    <div v-if="loading.getData">
      <b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mt-1 mb-1"/>
      <div class="table-skeleton-tableQuoter">
        <b-skeleton-table :rows="15" :columns="5" :table-props="{ }"/>
      </div>
    </div>
    <b-table
      responsive="sm"
      :key="keyTableRender"
      :items="rows"
      :fields="schema"
      borderless= "borderless"
      show-empty
      v-if="!loading.getData"
    >
      <template #empty="">
        <span class="text-center w-full push-left empty-pricing-web">{{ 'No se encontraron datos para mostrar' }}</span>
      </template>
      <template #head()="data">
        <span>
          {{ data.label }}
        </span>
      </template>
      <!-- Destinos (body) -->
      <template #cell(destination)="scope">
        <div class="row-title">
          <h6>{{ scope.item.name }}</h6>
        </div>
      </template>
      <template #cell()="scope">
        <span
          :class="[scope.field.minValue === scope.item[`price${scope.item[`${scope.field.key}`]}`] ? 'minValue' : '', 'allValues']"
          v-if="scope.item.hasOwnProperty([`price_formatted${scope.item[scope.field.key]}`])"
          >{{ scope.item[`price_formatted${scope.item[scope.field.key]}`] }}&nbsp;
          <feather-icon
            v-b-tooltip.html
            custom-class="tooltipCustomClass"
            :title="scope.item[`transit_days${scope.field.destination_id}`]"
            icon="ClockIcon"
          />
        </span>
        <span v-else-if="scope.field.key === 'separation'"></span>
        <span v-else>-</span>
      </template>
    </b-table>
    <p v-if="rows.length && !loading.getData" class="msj-table-pricingWeb">* Precios netos sujeto a condiciones de seguro</p>
    <div class="d-flex justify-content-end pt-4" v-if="!loading.getData">
      <h3 class="mr-2 p-0 m-0 align-self-center title-table-pricing-web">Si quieres conocer más alternativas</h3>
      <b-button class="color-button-pricingWeb" size="lg" @click="openInNewTab('https://enviame.io/contactanos/')">Contáctanos</b-button>
    </div>
  </div>
</template>

<script>
import BaseServices from '@/store/services/index'

export default {
  props: ['tableData', 'form', 'params', 'queryParams'],
  data() {
    return {
      baseService: new BaseServices(this),
      rows: [],
      schema: [],
      keyTableRender: 0,
      data: {},
      loading: {
        getData: false
      }
    }
  },
  watch: {
    tableData () {
      this.data = this.tableData
      this.rows = []
      this.schema = []
      this.setInitialData()
    }
  },
  methods: {
    setInitialData() {
      if (!this.data.data.length) return
      // Crear el head de la tabla
      this.schema = [
        {label: '', key: 'destination', useSlot: true, class: ['text-center', 'comparatorTableHead']}
      ]
      // Crear el body de la tabla
      const dataMeta = [...this.data.meta.carriers]
      dataMeta.forEach(data => {
        this.data.data.forEach(dataRep => {
          if (data.id === dataRep.carrier_id) {
            data[`destination_id${dataRep.to_place_id}`] = dataRep.to_place_id
            data[`price_formatted${dataRep.to_place_id}`] = dataRep.price_formatted
            data[`price${dataRep.to_place_id}`] = dataRep.price
            const transitDays = dataRep.transit_days?.split(',')
            data[`transit_days${dataRep.to_place_id}`] = dataRep.service_names.split(',').map((nameServ, i) => {
              let serviceName = `<strong>&#x2022; Servicio ${nameServ}</strong>`
              if (transitDays) serviceName += `<strong>:</strong> ${transitDays[i]} ${(transitDays[i] <= 1) ? 'día hábil' : 'días hábiles'}<br>`
              return serviceName
            }).join('')
          }
        })
      })
      // Obtiene el precio mínimo
      const minVal = dataMeta.reduce((acc, curr) => {
        return this.getSmallValue(acc, curr[`price${this.form.destination.id}`])
      }, Infinity)
      // Agrega el head de la tabla
      this.schema.push({
        label: this.form.destination.name,
        key: `destination_id${this.form.destination.id}`,
        destination_id: this.form.destination.id,
        useCarrierLogo: true,
        class: ['text-center', 'comparatorTableHead'],
        useSlot: true,
        minValue: minVal,
        useDangerHTML: true
      })
      this.rows = dataMeta
      this.keyTableRender++
    },
    getSmallValue (oldPrice, newPrice) {
      return (!oldPrice || (parseFloat(oldPrice) > parseFloat(newPrice))) ? newPrice : oldPrice
    },
    openInNewTab (url) {
      window.open(url, '_blank', 'noreferrer')
    }
  }
}
</script>

<style lang="scss" >
.msj-table-pricingWeb {
  padding-left: 25% !important;
}
.empty-pricing-web {
  color: #fff;
  float: none !important;
}
.title-table-pricing-web {
  color: #39549F;
  padding-left: 1.6rem !important;
}
.minValue{
  color: #28a745;
  font-weight: bold;
}
th.comparatorTableHead{
  background-color: #39549F !important;
  color: #fff;
}
.comparatorTableHead{
  > span {
    font-weight: 500;
  }
}
.comparatorTableBody{
  background-color: #F0F7FA !important;
}
.tooltip-inner{
  text-align: start !important;
  max-width: max-content !important;
  padding: 10px;
}
.pricing-web-table > div > table {
  border-spacing: 0 10px !important;
  border-collapse: separate;
  > thead > tr {
    background-color: #fff !important;
    .comparatorTableHead:nth-child(1) {
      background-color: transparent !important;
    }
    .comparatorTableHead:nth-child(2) {
      border-top-left-radius: 1rem !important;
    }
    .comparatorTableHead:nth-child(2) {
      border-bottom-left-radius: 1rem !important;
    }
    .comparatorTableHead:last-child {
      border-top-right-radius: 1rem !important;
    }
    .comparatorTableHead:last-child {
      border-bottom-right-radius: 1rem !important;
    }
  }
  > tbody{
    margin: 1rem;
    background-color: #F0F7FA !important;

    > tr > td:first-child {
      padding: 0 1.6rem 0 0 !important;
      background-color: #fff !important;
      width: 25% !important;
      > div {
        background-color: #39549F !important;
        border-radius: 1rem !important;
        padding: 0.5rem !important;
        text-align: center;
        > h6 {
          color: #fff !important;
          margin: 0.2rem;
        }
      }
    }
    > tr > .separation-thead-web {
      background-color: #fff !important;
    }
    > tr > .comparatorTableHead:nth-child(2) {
      border-top-left-radius: 1rem !important;
    }
    .comparatorTableHead:nth-child(2) {
      border-bottom-left-radius: 1rem !important;
    }
    .comparatorTableHead:last-child {
      border-top-right-radius: 1rem !important;
    }
    .comparatorTableHead:last-child {
      border-bottom-right-radius: 1rem !important;
    }
    > td {
      padding: 0 !important;
    }
  }
  .separation-thead-web {
    background-color: transparent !important;
  }
}
</style>
