<template>
  <div>
    <div class="title-quoter-pricingWeb">
      <img width="40rem" :src="require(`@/assets/images/icons/quoterIcons/quoter.png`)" class=""/>
      <h1 class="text-left">Cotizador</h1>
    </div>
    <form-render
      ref="formRenderPricingWeb"
      :key="keyFormRender"
      :fields="fields"
      :form.sync="form"
      containerButtonsClass="col-sm-12 col-md-12 col-lg-12 mt-2 mb-4"
    >
      <template #weighIcon><img width="40rem" :src="require(`@/assets/images/icons/quoterIcons/Peso.png`)" class="weight-icon-pricing-web"/></template>
      <template #buttons>
        <b-button
          variant="outline-light"
          v-b-tooltip.hover
          title="Limpiar filtros"
          class="quoterButton ml-2"
          @click="cleanFilter"
          :disabled="loading.callingService"
        >
          <feather-icon icon="RefreshCwIcon"/>
        </b-button>
        <b-button class="color-button-pricingWeb quoterButton" @click="validateForm" :disabled="loading.callingService">
          <span v-if="!loading.callingService" class="mb-1">Cotizar</span>
          <span class="mt-1 p-2" v-if="loading.callingService"><b-spinner small  /> </span>
        </b-button>
      </template>
    </form-render>
    <pricingWebList :tableData="dataRow" :params="paramsData" :queryParams="queryParamsData" :form="form" v-show="!loading.callingService && showTable"></pricingWebList>
    <div class="table-skeleton-tableQuoter mt-2 pt-2" v-if="loading.callingService">
      <b-skeleton-table :rows="15" :columns="5" :table-props="{ }"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import pricingWebList from './components-pricing-web/PricingWebList.vue'

export default {
  components: { pricingWebList },
  props: ['formWeb', 'tableData', 'params', 'queryParams'],
  data () {
    return {
      keyFormRender: 0,
      fields: [],
      form: {},
      baseService: new BaseServices(this),
      dataRow: [],
      loading: {
        callingService: false,
        firstCall: false
      },
      paramsData: {},
      queryParamsData: {},
      showTable: true
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountriesPricingWeb'
    })
  },
  watch: {
    countries() {
      this.setCountries()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.paramsData = this.params
      this.queryParamsData = this.queryParams
      this.fields = [
        { fieldType: 'FieldSelect', useSkeleton: true, useLabel: true, name: 'country', disabled: false, label: 'País', validation: 'required', containerClass: 'col-12 col-md-4 col-lg-4 pb-1', change: this.changeCountry, addFlags: true },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'origin', label: 'Origen', validation: 'required', containerClass: 'col-12 col-md-4 col-lg-4', dependency: 'country', clearable: true, searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600 }},
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'destination', label: 'Destino', validation: 'required', containerClass: 'col-12 col-md-4 col-lg-4', dependency: 'country', clearable: true, searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600 }},
        { name: 'line', useSkeleton: true, skipLine: true, containerClass: 'col-12' },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'classification', label: 'Clasificación servicio', validation: 'required', containerClass: 'col-12 col-md-4 col-lg-4', dependency: 'country', clearable: true },
        { name: 'weighIcon', useSlot: true, useLabel: true, containerClass: 'col-md-1 col-lg- col-sm-2 col-weighIcon' },
        { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'weightValue', label: 'Peso promedio bulto', type: 'number', validation: 'required', containerClass: 'col-md-3 col-lg-3 col-sm-10 col-weightValue', dependency: 'country', clearable: true, append: {text: 'Kg', color: '#BBBBBB'}, change: this.changeWeight}
      ]
      this.$store.dispatch('fetchService', { name: 'getCountriesPricingWeb' })
      this.form = this.formWeb
      this.dataRow = this.tableData
      if (this.tableData.data) this.loading.callingService = false
      if (this.formWeb?.country) this.getServicesTypes()
      if (this.formWeb?.country?.code === 'CO') this.fields.push({ fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'declaredValue', label: 'Valor declarado', type: 'number', validation: 'required', containerClass: 'col-12 col-md-4 col-lg-4', clearable: true, dependency: 'country'})
    },

    setCountries() {
      const indexCountry = this.fields.findIndex(el => el.name === 'country')
      this.fields[indexCountry].options = this.countries.rows
      this.setSkeletonField('country', false)
    },
    getServicesTypes () {
      this.setSkeletonField('classification', true)
      const params = {
        country_id: this.form.country.id
      }
      this.baseService.callService('getServiceTypesPricingWeb', null, params)
        .then(resp => {
          const serviceTypes = resp.data.filter(el => {
            if (this.form.country.code === 'MX' || this.form.country.code === 'CO') return el.type === 'nextday' || el.type === 'sameday' || el.type === 'estandar' || el.type === 'general'
            else return el.type === 'nextday' || el.type === 'sameday'
          })
          this.fields.forEach(el => { if (el.name === 'classification') el.options = serviceTypes.map(obj => ({ ...obj, text: obj.name })) })
          this.keyFormRender++
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {
            code: err.message
          }))
        })
        .finally(() => this.setSkeletonField('classification', false))
    },
    setSkeletonField (name, bool) {
      const index = this.fields.findIndex(el => el.name === name)
      this.fields[index].useSlot = bool
      this.fields[index].useSkeleton = bool
      this.fields[index].useLabel = bool
    },
    setPlaces(value) {
      const params = {
        country_id: this.form.country.id
      }
      const queryParams = {
        search: value
      }
      return this.baseService.callService('getPlacesPricingWeb', queryParams, params)
        .then(resp => {
          const response = resp.data.places.map(el => ({...el, text: el.full_name, valid: true}))
          return response
        })
        .catch(err => {
          return err
        })
    },
    changeCountry(name, value) {
      const indexDeclaredValue = this.fields.findIndex(el => el.name === 'declaredValue')
      if (indexDeclaredValue !== -1) this.fields.splice(indexDeclaredValue, 1) 
      Object.keys(this.form).forEach(el => { if (el !== 'country' && el !== 'line' && el !== 'weighIcon') this.form[el] = undefined })
      this.fields.forEach(el => { if (el.name !== 'country' && el.name !== 'line' && el.name !== 'weighIcon') { this.setSkeletonField(el.name, true) } })
      if (value) {
        this.getServicesTypes()
        if (value.code === 'CO') {
          this.fields.push({ fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'declaredValue', label: 'Valor declarado', type: 'number', validation: 'required', containerClass: 'col-12 col-md-4 col-lg-4', clearable: true, dependency: 'country'})
        }
      } else {
        Object.keys(this.form).forEach(el => { if (el !== 'country' && el !== 'line' && el.name !== 'weighIcon') this.form[el] = undefined })
      }
      this.fields.forEach(el => { if (el.name !== 'weighIcon') this.setSkeletonField(el.name, false) })
    },
    async validateForm () {
      const isCheck = await this.$refs.formRenderPricingWeb.onlyCheckForm()
      if (isCheck) this.buttonFind()
    },
    buttonFind() {
      this.loading.callingService = true
      if (!this.loading.firstCall) this.loading.firstCall = true
      const params = {
        country_id: this.form.country.id,
        from_place_id: this.form.origin.id,
        service_type_id: this.form.classification.id
      }
      const queryParams = {
        weight : this.form.weightValue,
        declared_value: this.form.declaredValue || '',
        ['to_place_ids[0]']: this.form.destination.id
      }
      this.paramsData = params
      this.queryParamsData = queryParams
      this.baseService.callService('getComparatorPricesPricingWeb', queryParams, params)
        .then(resp => {
          this.dataRow = resp
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {
            code: err.message
          }))
        })
        .finally(() => {
          this.loading.callingService = false
          this.showTable = true
        })
    },
    cleanFilter() {
      Object.keys(this.form).forEach(el => { if (el !== 'line' && el.name !== 'weighIcon') this.form[el] = undefined })
      this.fields.forEach(el =>  {
        if (el.name !== 'line' && el.name !== 'weighIcon' && el.name !== '') {
          this.setSkeletonField(el.name, true)
        }
      })
      this.loading.firstCall = false
      setTimeout(() => this.fields.forEach(el =>  { 
        if (el.name !== 'line' && el.name !== 'weighIcon' && el.name !== '') this.setSkeletonField(el.name, false) 
      }), 100)
      this.showTable = false
    },
    setFieldsProperties (fields, name, propertyField, value) {
      fields[propertyField] = value
    }
  }
}
</script>
<style lang="scss">
.color-button-pricingWeb {
  background: linear-gradient(90deg, #F49243, #F4BB43);
}
.weight-icon-pricing-web {
  width: 2.5 rem !important;
  margin: 1.3rem 0 0 1rem !important;
  position: relative;
}
.badge__cnt{
  margin: 5px;
  background:#1B4284;
  padding: 2px 5px;
  border-radius: 1rem;
  padding-left: 1rem;
}
.main-box-autocomplete {
  z-index: 99 !important;
  > .element-box-autocomplete{
    &:hover {
      white-space: normal !important;
    }
  }
}
.color_icon{ //color para el icono X de los tags
  stroke: white;
}
.title-quoter-pricingWeb {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  > h1 {
    align-self: center;
    margin: 0 !important;
    padding: 1rem;
  }
}
.col-weightValue {
  padding-left: 0;
}
.col-weighIcon{
  padding: 0px;
  text-align-last: center;
}
.tags-pricing-web {
  font-size: x-small;
}
</style>